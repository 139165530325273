import axios from 'axios'

class AuthAPI {
    static baseURL = process.env.REACT_APP_API_BASE_URL

    static async getUserDetails(token) {
        if (!token) {
            throw new Error('Authentication token is missing.')
        }

        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user-details/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async userLogin(data) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    static async userRegister(data) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    static async userUpdate({ data, token }) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/update-user-details/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getCountryChoices() {
        return axios.get(`${process.env.REACT_APP_API_BASE_URL}/config/get-country-data/`)
    }

    static async getCurrencyChoices({ token }) {
        return axios.get(`${process.env.REACT_APP_API_BASE_URL}/currencies/`)
    }

    static async setWelcomeScreenSeen({ token }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/set-welcome-screen-seen/`, null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async supportUs({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/support_us/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
}

export default AuthAPI
