// styles
import styles from './SupplierProductCatalogueView.module.scss'

// context
import AuthContext from '../../context/AuthContext'

// packages
import { useParams, useNavigate } from 'react-router'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useContext } from 'react'

import { Navigation } from 'swiper/modules'
import { useState } from 'react'
import { useRef } from 'react'
import cx from 'classnames'
import ImageCarousel from '../Imagecarousel/ImageCarousel'

// components
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Card from '../../components/Card/Card'
import SupplierAPI from '../../api/SupplierAPI'
import Loader from '../../components/Loader/Loader'
import Button from '../../components/Button/Button'
import { toast } from 'react-toastify'

const SupplierProductCatalogueView = () => {
    const { id } = useParams()
    const { user } = useContext(AuthContext)
    const swiperRef = useRef(null)
    const navigate = useNavigate()
    const [activeImage, setActiveImage] = useState(0)

    const getProductQuery = useQuery(['product', id], () => SupplierAPI.getProductById({ token: user.token, id }))
    // mutation to delete product

    const productData = getProductQuery.data?.data
    console.log('Product Images:', productData?.images)
    console.log(productData)
    const deleteProductMutation = useMutation(
        () => SupplierAPI.deleteProductById({ token: user.token, id: productData.id }),
        {
            onSuccess: () => {
                toast.success('Product deleted successfully')
                navigate('/supplier/products')
            },
            onError: () => {
                toast.error('Something went wrong')
            },
        }
    )
    const handleNavigate = () => {
        const { name, list_price_per_unit, description, images, documents } = productData
        const data = { name, list_price_per_unit, description, images, documents }
        navigate(`/supplier/products/edit/${id}`, { state: { data } })
    }
    const handleDelete = async () => {
        deleteProductMutation.mutate()
    }

    return (
        <SupplierLayout title="Product Catalogue" active="products">
            {getProductQuery.isError ? (
                <Loader refresh />
            ) : getProductQuery.isLoading ? (
                <Loader />
            ) : (
                <div>
                    <div className={styles.Product__contentWrapper}>
                        <Card className={styles.Product__detailsWrapper}>
                            <div>
                                <h2 className={styles.Product__detailsTitle}>{productData?.name}</h2>
                                <p className={styles.Product__details}>Category</p>
                                <p className={styles.Product__detailsValue}>{productData?.product_sub_category}</p>
                                <p className={styles.Product__details}>Price</p>
                                <p className={styles.Product__detailsValue}>
                                    {productData?.list_price_per_unit}&nbsp;
                                    {user?.organizationData?.currency || 'USD'}
                                </p>
                                <p className={styles.Product__details}>Availble</p>
                                <p className={styles.Product__detailsValue}>
                                    {productData?.is_available ? 'Yes' : 'No'}
                                </p>
                                <p className={styles.Product__details}>Description</p>
                                <p className={styles.Product__detailsValue}>{productData?.description}</p>

                                <p className={styles.Product__details}>Documents</p>
                                <ul className={styles.Product__documentList}>
                                    {productData?.documents?.map((doc, index) => (
                                        <li key={index} className={styles.Product__documentItem}>
                                            <span className={styles.Document__name}>{doc.name}</span>
                                            {doc.document && (
                                                <a
                                                    href={doc.document}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={styles.Document__downloadLink}
                                                >
                                                    Download
                                                </a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles.imagediv}>
                                <div className={styles.Product__imagesWrapper}>
                                    <div className={styles.Images}>
                                        {productData?.images?.length > 0 ? (
                                            <ImageCarousel images={productData.images} />
                                        ) : (
                                            <p className={styles.NoImages}>No images available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={styles.Btn__wrapper}>
                        <Button text={'Edit'} className={styles.Product__editButton} onClick={handleNavigate} />
                        <Button text={'Delete'} className={styles.Product__deleteButton} onClick={handleDelete} />
                    </div>
                </div>
            )}
        </SupplierLayout>
    )
}

export default SupplierProductCatalogueView
