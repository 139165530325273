import React, { useState } from 'react'
import styles from './ImageCarousel.module.scss'
import cx from 'classnames'

const ImageCarousel = ({ images }) => {
    const [activeImage, setActiveImage] = useState(0)

    const handlePrev = () => {
        setActiveImage((prev) => (prev - 1 + images.length) % images.length)
    }

    const handleNext = () => {
        setActiveImage((prev) => (prev + 1) % images.length)
    }

    return (
        <div className={styles.ImageCarousel}>
            <div className={styles.ImageCarousel__main}>
                <img
                    src={images[activeImage]?.image}
                    alt={`Product ${activeImage + 1}`}
                    className={styles.ImageCarousel__mainImage}
                />
                {images.length > 1 && (
                    <>
                        <button
                            className={cx(styles.ImageCarousel__control, styles.ImageCarousel__controlLeft)}
                            onClick={handlePrev}
                            aria-label="Previous Image"
                        >
                            ‹
                        </button>
                        <button
                            className={cx(styles.ImageCarousel__control, styles.ImageCarousel__controlRight)}
                            onClick={handleNext}
                            aria-label="Next Image"
                        >
                            ›
                        </button>
                    </>
                )}
            </div>
        </div>
    )
}

export default ImageCarousel
