import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierProductCatalogueEdit.module.scss'
import cx from 'classnames'
import InputWithError from '../../components/InputWithError/InputWithError'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import { useContext, useState } from 'react'
import Button from '../../components/Button/Button'
import AuthContext from '../../context/AuthContext'
import { toast } from 'react-toastify'
import SupplierAPI from '../../api/SupplierAPI'
import { useMutation } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import { useLocation, useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import { useNavigate } from 'react-router-dom'

const SupplierProductCatalogueEdit = () => {
    const { id } = useParams()
    const { user } = useContext(AuthContext)
    const location = useLocation()
    const productData = location.state.data
    const [form, setForm] = useState({
        ...productData,
    })

    console.log('Product Data:', productData)

    const [formErrors, setFormErrors] = useState({})
    const navigate = useNavigate()
    const [existingImages, setExistingImages] = useState(productData.images || [])

    const [existingDocuments, setExistingDocuments] = useState(productData.documents || [])

    const [newImages, setNewImages] = useState([])
    const [newDocuments, setNewDocuments] = useState([])

    const [selectedImageNames, setSelectedImageNames] = useState([])
    const [selectedDocNames, setSelectedDocNames] = useState([])

    const submitMutation = useMutation(SupplierAPI.updateProductById, {
        onSuccess: (response) => {
            console.log('API Response:', response)
            console.log('Updated Product Response:', response)
            setExistingImages(response.images || [])
            setExistingDocuments(response.documents || [])
            setNewImages([])
            setNewDocuments([])
            toast.success('Updated Product')
            navigate('/supplier/products')
        },
        onError: (err) => {
            console.error(err)
            if (err.response.status === 400) {
                return setFormErrors(err.response.data)
            }
            return toast.error(err.response.data?.message)
        },
    })

    const handleChange = (e) => {
        setForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files)
        const newImageNames = files.map((file) => file.name)
        setSelectedImageNames((prevNames) => [...prevNames, ...newImageNames])

        const allowedTypes = ['image/jpeg', 'image/png']

        const invalidFiles = files.filter((file) => !allowedTypes.includes(file.type))

        if (invalidFiles.length > 0) {
            toast.error('Only JPG, JPEG, and PNG images are allowed.')
            return
        }
        const imagePromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                if (!['image/jpeg', 'image/png'].includes(file.type)) {
                    toast.error(`${file.name} is not a valid image (JPEG or PNG).`)
                    reject()
                } else {
                    const reader = new FileReader()
                    reader.onload = () => {
                        resolve({ image: reader.result })
                    }
                    reader.readAsDataURL(file)
                }
            })
        })

        const newImagesList = await Promise.all(imagePromises)
        setNewImages((prev) => [...prev, ...newImagesList])
    }
    const handleDocumentChange = async (e) => {
        const files = Array.from(e.target.files)

        const newDocNames = files.map((file) => file.name)
        setSelectedDocNames((prev) => [...prev, ...newDocNames])

        const documentPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                if (file.type !== 'application/pdf') {
                    toast.error(`${file.name} is not a PDF file.`)
                    reject()
                } else {
                    const reader = new FileReader()
                    reader.onload = () => {
                        resolve({ name: file.name, document: reader.result })
                    }
                    reader.readAsDataURL(file)
                }
            })
        })

        const newDocumentsList = await Promise.all(documentPromises)
        setNewDocuments((prev) => [...prev, ...newDocumentsList])
    }

    const removeImage = (index, isNew) => {
        if (isNew) {
            setNewImages((prev) => prev.filter((_, i) => i !== index))
        } else {
            setExistingImages((prev) => prev.filter((_, i) => i !== index))
        }
    }

    const removeDocument = (index, isNew) => {
        if (isNew) {
            setNewDocuments((prev) => prev.filter((_, i) => i !== index))
        } else {
            setExistingDocuments((prev) => prev.filter((_, i) => i !== index))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            ...form,
            existing_images: existingImages.map((image) => image.id),
            new_images: newImages.map((image) => image.image),
            existing_documents: existingDocuments.map((doc) => doc.id),
            new_documents: newDocuments.map((doc) => ({ name: doc.name, document: doc.document })),
        }

        delete data.images

        console.log('Submitting data:', data)

        submitMutation.mutate({
            token: user.token,
            id,
            data,
        })
    }

    const handleCancel = () => {
        setForm({ ...productData })
        setExistingImages(productData.images || [])
        setNewImages([])
        navigate('/supplier/products')
    }

    const getSpanEstimate = (size) => {
        if (size > 250) {
            return 2
        }

        return 1
    }

    return (
        <SupplierLayout active="products" title="Product Catalogue">
            <form className={styles.AddProduct__container} onSubmit={handleSubmit} encType="multipart/form-data">
                <h2 className={styles.AddProduct__title}>Edit Product</h2>
                <Card>
                    <div className={cx('row')}>
                        <div className={cx('col-5')}>
                            <div className={cx(styles['mb-4'])}>
                                <InputWithError
                                    onChange={handleChange}
                                    name="name"
                                    label="Product Name"
                                    placeholder=""
                                    type="text"
                                    value={form.name}
                                    errorMsg={formErrors.name}
                                />
                            </div>

                            <div className={cx(styles['mb-4'])}>
                                <FormTextarea
                                    onChange={handleChange}
                                    name="description"
                                    label="Description"
                                    placeholder=""
                                    type="text"
                                    value={form.description}
                                    errorMsg={formErrors.description}
                                />
                            </div>
                        </div>
                        <div className={cx(styles['col-7'])}>
                            <div className={cx(styles['mb-4'])}>
                                <div className={styles.AddProduct__imageWrapper}>
                                    {existingImages.map((image, index) => (
                                        <div className={styles.imgbtn} key={`existing-${index}`}>
                                            <img
                                                className={styles.AddProduct__image}
                                                src={image.image || image.url}
                                                alt={`Image ${index + 1}`}
                                            />
                                            <button
                                                type="button"
                                                className={styles.ImgRemoveButton}
                                                onClick={() => removeImage(index, false)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    ))}
                                    {newImages.map((image, index) => (
                                        <div className={styles.imgbtn} key={`new-${index}`}>
                                            <img
                                                className={styles.AddProduct__image}
                                                src={image.image}
                                                alt={`New Image ${index + 1}`}
                                            />
                                            <button
                                                type="button"
                                                className={styles.ImgRemoveButton}
                                                onClick={() => removeImage(index, true)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.customFileInput}>
                                    <h4 className={styles.Imagelabell}>Product Images</h4>
                                    <div className={styles.fileInput}>
                                        <input
                                            type="file"
                                            id="images"
                                            name="images"
                                            accept="image/jpeg, image/png"
                                            multiple
                                            onChange={handleImageChange}
                                            className={styles.hiddenInput}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => document.getElementById('images').click()}
                                            className={styles.customButton}
                                        >
                                            Choose Files
                                        </button>
                                        <span className={styles.fileName}>
                                            {selectedImageNames.length > 0
                                                ? selectedImageNames.join(', ')
                                                : 'No file chosen'}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={cx(styles['mb-4'])}>
                                <div className={styles.AddProduct__docWrapper}>
                                    {existingDocuments.map((doc, index) => (
                                        <div key={`existing-doc-${index}`} className={styles.docbtn}>
                                            <span className={styles.docname}>Documents:{doc.name}</span>
                                            <button
                                                type="button"
                                                className={styles.DocRemoveButton}
                                                onClick={() => removeDocument(index, false)}
                                            >
                                                Remove
                                            </button>
                                            {doc.document && (
                                                <a
                                                    href={doc.document}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={styles.DownloadLink}
                                                >
                                                    <br />
                                                    <p>View / Download</p>
                                                </a>
                                            )}
                                        </div>
                                    ))}
                                    {newDocuments.map((doc, index) => (
                                        <div className={styles.docbtn} key={`new-doc-${index}`}>
                                            <span className={styles.docname}>{doc.name}</span>
                                            <button
                                                type="button"
                                                className={styles.DocRemoveButton}
                                                onClick={() => removeDocument(index, true)}
                                            >
                                                Remove
                                            </button>
                                            {doc.document && (
                                                <a
                                                    href={doc.document}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={styles.DownloadLink}
                                                >
                                                    <br />
                                                    <p>View / Download</p>
                                                </a>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={cx(styles['mb-4'])}>
                                    <h4 className={styles.Doclabell}>Product Documents</h4>
                                    <div className={styles.fileInput}>
                                        <input
                                            type="file"
                                            id="documents"
                                            name="documents"
                                            accept="application/pdf"
                                            multiple
                                            onChange={handleDocumentChange}
                                            className={styles.hiddenInput} // Hide default input
                                        />
                                        <button
                                            type="button"
                                            onClick={() => document.getElementById('documents').click()}
                                            className={styles.customButton}
                                        >
                                            Choose Files
                                        </button>
                                        <span className={styles.fileName}>
                                            {selectedDocNames.length > 0
                                                ? selectedDocNames.join(', ')
                                                : 'No file chosen'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={cx(styles['row'], styles['mb-4'])}>
                                <div className={cx(styles['col-6'])}>
                                    <InputWithError
                                        onChange={handleChange}
                                        name="list_price_per_unit"
                                        label="Price Per Item"
                                        placeholder=""
                                        type="text"
                                        value={form.list_price_per_unit}
                                        errorMsg={formErrors.list_price_per_unit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.Btn__Container}>
                        <Button text="Cancel" className={styles.AddProduct__cancelButton} onClick={handleCancel} />
                        <Button text="Update" loading={submitMutation.isLoading} onClick={(e) => handleSubmit(e)} />
                    </div>
                </Card>
            </form>
        </SupplierLayout>
    )
}

export default SupplierProductCatalogueEdit
